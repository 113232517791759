/* Bootstrap core imports */ 
@import "../../../node_modules/bootstrap/scss/bootstrap";

/* Template CSS variables */
@import "./abstracts/variables";
@import "./abstracts/css-variables";

/* Templates components */
@import "./components/navbar";
@import "./components/logos";
@import "./components/subtitles";
@import "./components/backgrounds";
@import "./components/card";
@import "./components/navs-and-tabs";
@import "./components/list-group";
@import "./components/buttons";
@import "./components/accordion";
@import "./components/breadcrumb";
@import "./components/modal";
@import "./components/containers";

/* Template content */
@import "./content/images";
@import "./content/reboot";

/* Template utilities imports */
@import "./utilities/sizing";
@import "./utilities/utilities";

/* global */
@import "./global/variables-dark";
@import "./global/root";